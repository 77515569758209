// global
import store from '@/store/store.js'

class QueryRetransformer {
  constructor() {
    this.query = store.getters['AdvancedQuery/selectedQuery']
    this.queryRules = store.getters['AdvancedQuery/queryRules']
    this.elements = this.query.elements
    this.criteria = this.query.criteria
    this.ruleCounts = this.query.counts
    // TODO: need to use a reducer function
    this.queryElements = []
    this.customElements = []
  }

  getElementInfo(element, criteria) {
    let queryElement = element.element[0]
    let elementInfo = {}
    let segmentRule = {}
    let operandRule = {}
    let childOperandRule = {}
    let elementType = queryElement.element_type
    let actionTaken = []
    let outputType = null
    let outputTypeLabel = []
    let elementOption = []
    /* Special Case: Total CU Members (10081), Deposit Accounts (1931), Share Accounts (10014) are metrics  come under Firmographics */
    if (['10081', '291', '1931', '10014'].includes(queryElement.element_id))
      elementType = 'firmographics'
    switch (elementType) {
      case 'market_area':
        segmentRule = this.queryRules.find(qr => qr.label === 'Market')
        operandRule = segmentRule.children.find(
          or => or.element_property === queryElement.element_property
        )
        elementInfo = {
          ruleId: segmentRule.id,
          operand: operandRule.id,
          childOperand: null,
          source: operandRule.source,
          cuSource: operandRule.cu_source
        }
        break
      case 'digital_channels':
        segmentRule = this.queryRules.find(
          qr => qr.label === 'Digital Channels'
        )
        operandRule = segmentRule.children.find(
          or => or.element_property === queryElement.element_property
        )

        elementInfo = {
          ruleId: segmentRule.id,
          operand: operandRule.id,
          childOperand: null,
          source: operandRule.source,
          cuSource: operandRule.cu_source
        }
        break
      case 'segmentation':
        segmentRule = this.queryRules.find(qr => qr.label === 'Segmentation')
        operandRule = segmentRule.children.find(
          or => or.element_property === queryElement.element_property
        )

        elementInfo = {
          ruleId: segmentRule.id,
          operand: operandRule.id,
          childOperand: null,
          source: operandRule.source,
          cuSource: operandRule.cu_source
        }
        break
      case 'firmographics':
        segmentRule = this.queryRules.find(qr => qr.label === 'Firmographics')

        if (['10081', '291', '1931', '10014'].includes(queryElement.element_id))
          operandRule = segmentRule.children.find(
            sr => sr.element_id === queryElement.element_id
          )
        else if (queryElement.element_properties) {
          operandRule = segmentRule.children.find(
            sr =>
              sr.element_property ===
              queryElement.element_properties[0].element_property
          )
          actionTaken = queryElement.element_properties[0].action_taken

          outputType = queryElement.element_properties[0].output_type

          outputTypeLabel = queryElement.element_properties[0].output_type.label

          elementOption = queryElement.element_properties[0].element_option
        } else
          operandRule = segmentRule.children.find(
            sr => sr.element_property === queryElement.element_property
          )
        elementInfo = {
          ruleId: segmentRule.id,
          operand: operandRule.id,
          childOperand: null,
          source: operandRule.source,
          cuSource: operandRule.cu_source,
          actionTaken: actionTaken,
          outputType: outputType,
          outputTypeLabel: outputTypeLabel,
          elementOption: elementOption
        }
        break
      case 'mortgage_lending':
        segmentRule = this.queryRules.find(
          qr => qr.label === 'Mortgage Lending'
        )
        outputTypeLabel = queryElement.element_properties[0].output_type.label
        elementInfo = {
          ruleId: segmentRule.id,
          operand: queryElement.element_properties[0].output_type,
          childOperand: null,
          source: null,
          cuSource: null,
          actionTaken: queryElement.element_properties[0].action_taken,
          outputType: queryElement.element_properties[0].output_type,
          outputTypeLabel: outputTypeLabel,
          filterOperator: criteria.filter_operator || 'AND',
          filterOn: queryElement.filters.length > 0,
          filters: this.reTransformToFilterElement(
            queryElement.filters,
            criteria,
            segmentRule.filters
          )
        }
        break
      case 'common_metric':
        segmentRule = this.queryRules.find(qr => qr.id === 'common-metrics')

        elementInfo = {
          ruleId: segmentRule.id,
          operand: queryElement.element_id,
          operandLabel: element.metric_name,
          fiType: 'both',
          childOperand: null,
          source: null,
          cuSource: null
        }
        break
      case 'metrics':
        segmentRule = this.queryRules.find(qr => qr.id === 'detailed-metrics')

        elementInfo = {
          ruleId: segmentRule.id,
          operand: queryElement.element_id,
          operandLabel: element.metric_name,
          fiType: element.fi_type,
          childOperand: null,
          source: null,
          cuSource: null
        }
        break
      case 'common_financial_statements':
        segmentRule = this.queryRules.find(
          qr => qr.id === 'financial-statements'
        )

        elementInfo = {
          ruleId: segmentRule.id,
          operand: queryElement.element_id,
          operandLabel: element.statement_name,
          fiType: 'both',
          childOperand: null,
          source: null,
          cuSource: null
        }
        break
      case 'financial_statements':
        segmentRule = this.queryRules.find(
          qr => qr.id === 'detailed-statements'
        )

        elementInfo = {
          ruleId: segmentRule.id,
          operand: queryElement.element_id,
          operandLabel: element.statement_name,
          fiType: element.fi_type,
          childOperand: null,
          source: null,
          cuSource: null
        }
        break
      case 'performographics':
        segmentRule = this.queryRules.find(
          qr => qr.label === 'Performographics'
        )

        operandRule = segmentRule.children.find(or => {
          return (
            or.element_type === queryElement.element_type &&
            or.element_id === queryElement.element_id
          )
        })

        elementInfo = {
          ruleId: segmentRule.id,
          operand: operandRule.id,
          childOperand: null,
          source: operandRule.source,
          cuSource: operandRule.cu_source
        }
        break
      case 'technographics':
        if (queryElement.element_section === 'digital_channels') {
          segmentRule = this.queryRules.find(
            qr => qr.label === 'Digital Channels'
          )
          operandRule = segmentRule.children.find(
            sr => sr.element_id === queryElement.element_id
          )

          elementInfo = {
            ruleId: segmentRule.id,
            operand: operandRule.id,
            childOperand: null,
            source: null,
            cuSource: null,
            section: 'digital_channels'
          }
        } else {
          segmentRule = this.queryRules.find(qr => qr.id === 'technographics')

          elementInfo = {
            ruleId: segmentRule.id,
            operand: parseInt(queryElement.element_id),
            operandLabel: element.bank_service_name,
            fiType: null,
            childOperand: null,
            source: null,
            cuSource: null
          }
        }

        break
      case 'vendor_fit':
        segmentRule = this.queryRules.find(qe => qe.label === 'Core Processing')
        operandRule = segmentRule.children.find(
          or =>
            or.label ===
            (queryElement.element_properties[0].product_equal
              ? 'product equal'
              : 'product not equal')
        )
        childOperandRule = operandRule.children.find(
          cor => cor.element_id === queryElement.element_id
        )

        elementInfo = {
          ruleId: segmentRule.id,
          operand: operandRule.id,
          childOperand: childOperandRule.id,
          source: childOperandRule.source,
          cuSource: childOperandRule.cu_source
        }
        break
      case 'job_search':
        elementInfo = {
          ruleId: this.queryRules.find(qr => qr.label === 'Job Search').id
        }
        break
      case 'site_search':
        elementInfo = {
          ruleId: this.queryRules.find(qr => qr.label === 'Site Search').id
        }
        break
      case 'contact_search':
        elementInfo = {
          ruleId: this.queryRules.find(qr => qr.label === 'Contact Search').id
        }
        break
      case 'select_institutions':
        elementInfo = {
          ruleId: this.queryRules.find(qr => qr.label === 'Select Institutions')
            .id,
          source: queryElement.element_property
        }
        break
      default:
        break
    }

    return elementInfo
  }
  reTransformToFilterElement(QEfilters, criteria, filterList) {
    let filters = []
    QEfilters &&
      QEfilters.forEach((fe, index) => {
        let selectedFilter = filterList.find(f => {
          return f.id === fe.filter_id
        })
        let selectedFilterChild = selectedFilter.children.find(child => {
          return child.id === fe.element_id
        })
        filters.push({
          id: criteria.id + '_' + fe.id,
          ruleId: criteria.id,
          filterId: fe.filter_id,
          type: 'filter',
          childOperand: selectedFilterChild,
          value: criteria.filters[index].value,
          count: this.ruleCounts[criteria.id + '_' + fe.id],
          error: false,
          operand: null,
          operator: criteria.filters[index].operator,
          loading: false
        })
      })
    return filters
  }

  buildQueryRules(criteria) {
    if (criteria.type === 'group') {
      return {
        id: criteria.id,
        type: criteria.type,
        primary: criteria.primary,
        operator: criteria.operator,
        children: criteria.children.map(ruleCriteria => {
          return this.buildQueryRules(ruleCriteria)
        })
      }
    } else {
      let element = this.elements.find(e => e.id === criteria.element)
      let elementInfo = this.getElementInfo(element, criteria)

      let fitType =
        element.element[0].element_type === 'vendor_fit'
          ? element.element[0].element_properties[0].fit_type
          : null

      return {
        id: criteria.id,
        type: criteria.type,
        ruleId: elementInfo.ruleId,
        operand: elementInfo.operand,
        childOperand: elementInfo.childOperand,
        fitType: fitType,
        source: elementInfo.source,
        cuSource: elementInfo.cuSource,
        operator: criteria.operator,
        value: criteria.value,
        count: this.ruleCounts[criteria.id],
        section: elementInfo.section,
        error: false,
        label: false,
        loading: false,
        operandLabel: elementInfo.operandLabel || null,
        fiType: elementInfo.fiType || null,
        filterOperator: elementInfo.filterOperator || 'AND',
        filterOn: elementInfo.filterOn || false,
        actionTaken: elementInfo.actionTaken || [],
        outputType: elementInfo.outputType || null,
        elementOption: elementInfo.elementOption || [],
        outputTypeLabel: elementInfo.outputTypeLabel || null,
        filters: elementInfo.filters || [
          {
            id: '1_1_1',
            ruleId: '1_1',
            filterId: null,
            type: 'filter',
            childOperand: null,
            value: [],
            count: null, //need to get count from api
            error: false,
            operand: null,
            operator: null,
            loading: false
          }
        ]
      }
    }
  }

  // TODO: this may be needed in future - but unused now
  buildQueryElements(criteria) {
    if (criteria.type === 'group') {
      criteria.children.forEach(ruleCriteria =>
        this.buildQueryElements(ruleCriteria)
      )
    } else {
      let element = this.elements.find(e => e.id === criteria.element)
      this.queryElements.push(element)
    }
  }

  // TODO: this may be needed in future - but unused now
  buildCustomElements(criteria) {
    this.buildQueryElements(criteria)
    let queryElementIDs = this.queryElements.map(qe => qe.id)
    this.customElements = this.elements.filter(
      e => !queryElementIDs.includes(e.id)
    )
  }

  get retransform() {
    // this.buildCustomElements(this.criteria)
    return this.buildQueryRules(this.criteria)
  }
}

export default QueryRetransformer
