<template>
  <div>
    <b-card
      :class="[
        isPrimaryGroup() ? '' : 'rounded',
        'std-border',
        'depth-' + groupDepth()
      ]"
      header-class="p-2"
      body-class="p-2"
    >
      <div slot="header">
        <div class="row align-items-center ">
          <div class="col-sm-11 col-md-11 text-left">
            <h6 class="mb-0 d-inline-block font-weight-bold">
              <label for="operator" class="d-inline-block mb-0">
                {{ isPrimaryGroup() ? 'Primary' : 'Group' }} Criteria Condition:
              </label>
            </h6>
            <div class="d-inline-block  ml-2 mr-3">
              <multiselect
                v-model="operatorObj"
                track-by="value"
                label="text"
                :options="operatorOptions"
                :multiple="false"
                :close-on-select="true"
                :showLabels="false"
                placeholder="Select one"
                class="simple-select group-select form-control rounded advanced-query-select"
                @close="criteriaChange()"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option-text">{{ props.option.text }}</span>
                </template>
              </multiselect>
            </div>
            <!-- <select
              id="vqb-match-type"
              class="form-control-sm ml-2 mr-3"
              v-model="query.operator"
              @change="criteriaChange()"
            >
              <option selected>AND</option>
              <option>OR</option>
            </select> -->
            <h6 class="mb-0 d-inline-block">
              Financial institutions must pass
              <b class="underline-txt">{{
                query.operator === 'AND' ? 'ALL' : 'at least ONE'
              }}</b>
              of the criteria in this group
            </h6>
          </div>
          <div class="col-sm-1 text-right" v-if="!isPrimaryGroup()">
            <div class="close-icon" @click="removeGroup">
              <i class="fa fa-times-circle fa-lg"></i>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          class="d-flex mb-2"
          v-for="(child, index) in query.children"
          :key="index"
        >
          <QueryRuleForMBC
            :rules="rules"
            :query="child"
            :rootQuery="query"
            :index="index"
            :groupOperator="query.operator"
            @updateQuery="updateQuery"
            @cloneRule="cloneRule"
            @removeRule="removeRule"
            @removeGroup="deleteGroup"
            @clearResult="clearResult"
            @addFilter="updateQuery"
            v-if="isMortgageLender"
          ></QueryRuleForMBC>
          <query-rule
            :rules="rules"
            :query="child"
            :rootQuery="query"
            :index="index"
            :groupOperator="query.operator"
            @updateQuery="updateQuery"
            @cloneRule="cloneRule"
            @removeRule="removeRule"
            @removeGroup="deleteGroup"
            @clearResult="clearResult"
            v-else
          ></query-rule>
        </div>
      </div>
      <div class="row">
        <div class="col-12 group-modifier">
          <button
            type="button"
            class="btn btn-primary rounded mr-1"
            @click="addRule()"
          >
            Add Criteria
          </button>
          <button
            type="button"
            class="btn btn-primary rounded"
            @click="addGroup()"
          >
            Add Group
          </button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import deepClone from '@/utilities.js'
import Multiselect from 'vue-multiselect'
import Vue from 'vue'

export default {
  name: 'query-group',
  components: {
    Multiselect,
    QueryRule: () => import('./QueryRule'),
    QueryRuleForMBC: () => import('./QueryRuleForMBC')
  },
  props: {
    rules: {
      type: Array
    },
    query: {
      type: Object
    }
  },
  data() {
    return {
      operatorObj: { text: 'AND', value: 'AND' },
      operatorOptions: [
        { text: 'AND', value: 'AND' },
        { text: 'OR', value: 'OR' }
      ]
    }
  },
  mounted() {
    if (this.query.operator) {
      this.operatorObj = this.operatorOptions.find(
        opt => opt.value == this.query.operator
      )
    }
  },
  computed: {
    ...mapGetters('AdvancedQuery', ['isMortgageLender'])
  },
  methods: {
    isPrimaryGroup() {
      return this.query && this.query.primary
    },
    addRule() {
      let query = deepClone(this.query)
      let ruleID = this.generateNewId()

      query.children.push({
        id: ruleID,
        type: 'rule',
        ruleId: null,
        source: null,
        cuSource: null,
        operand: null,
        childOperand: null,
        fitType: null,
        operator: null,
        value: [],
        count: null,
        error: false,
        label: null,
        loading: false,
        operandLabel: null,
        outputTypeLabel: null,
        fiType: null,
        filterOperator: 'AND',
        filterOn: false,
        actionTaken: [],
        outputType: null,
        elementOption: [],
        filters: [
          {
            id: ruleID + '_1',
            ruleId: ruleID,
            filterId: null,
            type: 'filter',
            childOperand: null,
            value: [],
            count: null,
            error: false,
            operand: null,
            operator: null,
            loading: false
          }
        ]
      })

      this.$emit('updateQuery', query)
    },
    addGroup() {
      let query = deepClone(this.query)
      let groupID = this.generateNewId()

      query.children.push({
        id: groupID,
        type: 'group',
        operator: 'AND',
        primary: false,
        children: [
          {
            id: groupID + '_' + 1,
            type: 'rule',
            ruleId: null,
            source: null,
            cuSource: null,
            operand: null,
            childOperand: null,
            fitType: null,
            operator: null,
            value: [],
            count: null,
            error: false,
            label: null,
            loading: false,
            operandLabel: null,
            outputTypeLabel: null,
            fiType: null,
            filterOperator: 'AND',
            filterOn: false,
            actionTaken: [],
            outputType: null,
            elementOption: [],
            filters: [
              {
                id: groupID + '_1_1',
                ruleId: groupID + '_1',
                filterId: null,
                type: 'filter',
                childOperand: null,
                value: [],
                count: null,
                error: false,
                operand: null,
                operator: null,
                loading: false
              }
            ]
          }
        ]
      })

      this.$emit('updateQuery', query)
    },
    criteriaChange() {
      if (this.operatorObj) this.query.operator = this.operatorObj.value
      let query = deepClone(this.query)
      this.$emit('updateQuery', query)
    },
    updateQuery(newQuery) {
      let updateQuery = deepClone(this.query)
      let updateIndex = updateQuery.children.findIndex(
        q => q.id === newQuery.id
      )
      updateQuery.children[updateIndex] = newQuery
      this.$emit('updateQuery', updateQuery)
    },
    cloneRule(chosenRule) {
      let updateQuery = deepClone(this.query)
      let clonedRule = deepClone(chosenRule)
      let ruleIndex = updateQuery.children.findIndex(
        child => child.id === chosenRule.id
      )

      updateQuery.children.splice(ruleIndex + 1, 0, clonedRule)
      updateQuery.children.slice(ruleIndex + 1).forEach(child => {
        child.id = this.incrementedUniqId(child)
      })
      this.$emit('updateQuery', updateQuery)
    },
    clearResult() {
      this.$emit('clearResult')
    },
    removeRule(uniqId) {
      if (this.query.children.length === 1) {
        Vue.toasted.show('A Group should have atleast one criteria', {
          icon: 'chain-broken',
          type: 'error'
        })
        return
      }
      let updateQuery = deepClone(this.query)
      let ruleIndex = updateQuery.children.findIndex(
        child => child.id === uniqId
      )
      updateQuery.children.splice(ruleIndex, 1)
      this.$emit('updateQuery', updateQuery)
    },
    removeGroup() {
      this.$emit('removeGroup', this.query.id)
    },
    deleteGroup(groupId) {
      if (this.query.children.length === 1) {
        Vue.toasted.show('A Group should have atleast one criteria', {
          icon: 'chain-broken',
          type: 'error'
        })
        return
      }
      let updateQuery = deepClone(this.query)
      let ruleIndex = updateQuery.children.findIndex(
        child => child.id === groupId
      )
      updateQuery.children.splice(ruleIndex, 1)
      this.$emit('updateQuery', updateQuery)
    },
    generateNewId() {
      if (this.query.children.length > 0) {
        let oldId = this.query.children
          .pop()
          .id.split('_')
          .pop()
        let newId = parseInt(oldId) + 1
        return this.query.id + '_' + newId
      } else {
        return this.query.id + '_' + 1
      }
    },
    incrementedUniqId(rule) {
      let oldUniqId = rule.id.split('_').pop()
      let newUniqId = parseInt(oldUniqId) + 1

      return this.query.id + '_' + newUniqId
    },
    groupDepth() {
      if (this.isPrimaryGroup()) {
        return ''
      }
      return (this.query.id.split('_').length - 1) % 4
    }
  },
  watch: {
    'query.operator': {
      handler: function() {
        if (this.query.operator) {
          this.operatorObj = this.operatorOptions.find(
            opt => opt.value == this.query.operator
          )
        } else {
          this.operatorObj = { text: 'AND', value: 'AND' }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.multiselect.advanced-query-select {
  .multiselect__single {
    padding-top: 2px;
    .option-text {
      font-size: 0.875rem;
    }
  }
}
#query-tool {
  .underline-txt {
    text-decoration: underline;
  }

  .depth-1 {
    border-left: 3px solid #8bc34a;
  }

  .depth-2 {
    border-left: 3px solid #00bcd4;
  }

  .depth-3 {
    border-left: 3px solid #ff5722;
  }

  .depth-0 {
    border-left: 3px solid #c69500;
  }

  .group-modifier {
    margin-left: 2.4rem;
  }
}
</style>
