<template>
  <b-modal
    id="savedQueriesList"
    title="Select a Query to load"
    size="xl"
    hide-footer
    scrollable
    @show="loadInitData()"
  >
    <v-wait for="loadingSavedQueries">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="pt-4">
          <content-placeholders-text :lines="15"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row">
        <!-- <div class="col-sm-9"></div> -->
        <div class="col-sm-3 mb-3 align-right">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="filter"
              placeholder="Search"
              ref="search"
            ></b-form-input>
            <div class="input-group-prepend">
              <button type="button" class="btn btn-secondary">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </b-input-group>
        </div>
      </div>
      <b-table
        striped
        hover
        small
        :items="queryList"
        :fields="tableFields"
        :class="{ processing: processingData }"
        :show-empty="true"
        :filter="filter"
        :filter-included-fields="getFilterableFields"
        :current-page="currentPage"
        :per-page="perPage"
        @filtered="onFiltered"
        responsive="md"
        empty-text="No records found."
      >
        <template v-slot:cell(name)="data">
          <div>
            {{ data.item.name }}
            <span class="text-muted" v-if="isFinApiEnterpriseUser"
              >[{{ data.item.id }}]</span
            >
          </div>
        </template>
        <template v-slot:table-colgroup="scope">
          <col v-for="field in scope.fields" :key="field.key" />
        </template>
        <template v-slot:cell(owner)="data">
          <div v-if="data.item.group_ids && data.item.group_ids.length">
            <h5>
              <b-badge pill variant="secondary" class="px-1">Shared</b-badge>
            </h5>
          </div>
          <div class="px-1" v-else>
            {{ data.item.first_name }} {{ data.item.last_name }}
          </div>
        </template>
        <template v-slot:cell(action)="data">
          <div class="text-center">
            <button
              class="btn btn-primary btn-sm rounded mr-1"
              @click="loadQuery(data.item)"
            >
              Load
            </button>
            <button
              class="btn btn-secondary btn-sm rounded ml-1"
              @click="deleteQuery(data.item)"
            >
              Delete
            </button>
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col md="6">
          <b-pagination
            :total-rows="getLength"
            :per-page="perPage"
            v-model="currentPage"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </v-wait>
  </b-modal>
</template>

<script>
//global
import Vue from 'vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
//api
import advancedQueryAPI from '@/modules/advanced_query/api/advanced.query'

export default {
  name: 'AQSavedQueries',
  props: {
    renderedIn: {
      type: String,
      required: false,
      default: 'Advanced Query'
    },
    fiType: {
      type: String,
      required: false,
      default: 'both'
    }
  },
  data() {
    return {
      perPage: 15,
      totalRows: 1,
      filter: null,
      currentPage: 1,
      processingData: false,
      filteredDataLength: 1,
      tableFields: [
        { key: 'name', label: 'Name', sortable: true },
        {
          key: 'fi_type',
          label: 'FI Type',
          sortable: true,
          filterByFormatted: true,
          formatter: value => {
            return Vue.filter('formatFIType')(value)
          }
        },
        { key: 'description', label: 'Description', sortable: false },
        { key: 'owner', label: 'Owner', sortable: false },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          class: 'text-center'
        }
      ],
      filterOn: ['name', 'fi_type', 'id', 'description'],
      queryList: []
    }
  },
  computed: {
    ...mapState('AdvancedQuery', {
      selectedQuery: state => state.selectedQuery,
      savedQueryList: state => state.savedQueryList
    }),
    ...mapGetters('Ability', ['isFinApiEnterpriseUser']),

    getFilterableFields() {
      return this.isFinApiEnterpriseUser
        ? this.filterOn
        : ['name', 'fi_type', 'description']
    },
    getLength() {
      return this.filteredDataLength
    }
  },
  methods: {
    ...mapMutations('AdvancedQuery', [
      'setSelectedQuery',
      'setPersistedQuery',
      'removeDeletedQuery'
    ]),
    ...mapActions('AdvancedQuery', ['fetchSavedQueries']),
    loadInitData() {
      this.filter = null
      this.currentPage = 1
      this.$wait.start('loadingSavedQueries')
      this.fetchSavedQueries()
        .then()
        .finally(() => {
          if (this.renderedIn !== 'Advanced Query') {
            this.queryList = this.savedQueryList.filter(cr => {
              return cr.fi_type === this.fiType
            })
          } else {
            this.queryList = this.savedQueryList
          }
          this.totalRows = this.queryList.length
          this.filteredDataLength = this.queryList.length
          this.$wait.end('loadingSavedQueries')
        })
    },
    loadQuery(query) {
      this.setSelectedQuery(query)
      this.setPersistedQuery(null)
      this.$emit('querySelected')
      this.$bvModal.hide('savedQueriesList')
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.filteredDataLength = filteredItems.length
    },
    deleteQuery(query) {
      this.$dialog
        .confirm(
          {
            title: 'Alert',
            body: 'Are you sure, you want to delete the query?'
          },
          {
            okText: 'Delete',
            cancelText: 'Cancel'
          }
        )
        .then(() => {
          this.processingData = true

          advancedQueryAPI.deleteQuery(query.id).then(res => {
            if (res.success) {
              this.removeDeletedQuery(query)
              this.$toasted.show('Query successfully deleted.', {
                icon: 'user-circle',
                type: 'success'
              })

              if (this.selectedQuery && this.selectedQuery.id === query.id) {
                this.$emit('resetQueryRules')
              }
              this.$bvModal.hide('savedQueriesList')
              this.processingData = false
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
